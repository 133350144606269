import styled from 'styled-components';

type Props = {
    imgUrl: string;
};

export const Container = styled.div<Props>`
    background-image: url(${(props) => props.imgUrl});
    border-radius: 50%;
    width: 46px;
    height: 46px;
    background-size: cover;
    background-position: center;
`;
