import styled from 'styled-components';

export const Input = styled.input`
    border-radius: 0.625rem;
    padding: 1rem;
    margin: 0.5rem 0;
    width: calc(100% - 32px);
    border: 0.125rem solid var(--color-neutral-light);
    background-color: transparent;
    color: var(--color-neutral-white);
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 1rem;
    font-size: 0.875rem;
    &:focus {
        border-color: var(--color-brand-pink);
        outline: none;
        + label {
            color: var(--color-neutral-white);
        }
    }
`;

export const Label = styled.label`
    display: block;
    color: var(--color-neutral-light);
    font-family: 'Inter', sans-serif;
    font-size: 0.625rem;
    line-height: 1rem;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
`;
