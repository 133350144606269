import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';

import {
    checkRatingResult,
    getCheckoutLink,
    getRating,
    getUserInfo,
} from '../../api';
import { Button } from '../../components/Button';
import { FormGroup } from '../../components/Form/form';
import { Input, Label } from '../../components/Form/input';
import { Header } from '../../components/Header';
import { LoadingModal } from '../../components/LoadingModal';
import { ContentContainer, Container } from './styles';

const EmptyState = () => {
    return (
        <div className="empty-state">
            <h3>
                Please fill the trader id and the period to check the rating
            </h3>
        </div>
    );
};

const Rating = ({
    value,
    traderId,
    startPeriod,
    endPeriod,
}: {
    value: number;
    traderId: string;
    startPeriod: string;
    endPeriod: string;
}) => {
    return (
        <div className="rating">
            <div className="rating-value">{value}</div>
            <p>
                Rating for trader id <b>#{traderId}</b> from{' '}
                <b>{startPeriod}</b> to <b>{endPeriod}</b>
            </p>
        </div>
    );
};

const SubscriptionBanner = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(false);

    const activeHandler = useCallback(async () => {
        setIsLoading(true);
        const { url } = await getCheckoutLink(getAccessTokenSilently);
        window.location.href = url;
    }, [getAccessTokenSilently]);

    return isLoading ? (
        <LoadingModal />
    ) : (
        <div className="subscription-banner">
            <h2>
                Before accessing the rating dashboard, you need to have an
                active subscription
            </h2>
            <div>
                <Button type="INFO" size="LG" onClick={activeHandler}>
                    Active my subscription
                </Button>
            </div>
        </div>
    );
};

export const MainPage = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(true);
    const [paymentStatus, setPaymentStatus] = useState<'OK' | 'PENDING'>();
    const [rating, setRating] = useState<number>();

    const [traderId, setTraderId] = useState<string>('');
    const [startPeriod, setStartPeriod] = useState<string>('');
    const [endPeriod, setEndPeriod] = useState<string>('');

    const [loadedState, setLoadedState] = useState<{
        traderId: string;
        startPeriod: string;
        endPeriod: string;
    }>();

    const loadUserInfoHandler = useCallback(async () => {
        const { paymentStatus } = await getUserInfo(getAccessTokenSilently);
        setPaymentStatus(paymentStatus);
        setIsLoading(false);
    }, [getAccessTokenSilently]);

    useEffect(() => {
        loadUserInfoHandler();
    }, [loadUserInfoHandler]);

    const isButtonEnabled = !!traderId && !!startPeriod && !!endPeriod;

    const loadRatingHandler = useCallback(async () => {
        setIsLoading(true);

        const { requestId } = await getRating(
            getAccessTokenSilently,
            startPeriod,
            endPeriod,
            traderId
        );

        const intervalId = setInterval(async () => {
            const { status, result } = await checkRatingResult(
                getAccessTokenSilently,
                requestId
            );
            if (status === 'OK') {
                clearInterval(intervalId);
                setRating(result);
                setLoadedState({ traderId, startPeriod, endPeriod });
                setIsLoading(false);
            }
        }, 1500);
    }, [endPeriod, getAccessTokenSilently, startPeriod, traderId]);

    return isLoading ? (
        <LoadingModal />
    ) : (
        <div>
            <Header />
            <ContentContainer>
                <Container>
                    {paymentStatus === 'PENDING' ? (
                        <SubscriptionBanner />
                    ) : (
                        <>
                            <div className="actions">
                                <div className="inputs">
                                    <FormGroup>
                                        <Input
                                            type="string"
                                            value={traderId}
                                            onChange={(e) =>
                                                setTraderId(e.target.value)
                                            }
                                        />
                                        <Label>Trader id</Label>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            type="date"
                                            value={startPeriod}
                                            onChange={(e) =>
                                                setStartPeriod(e.target.value)
                                            }
                                        />
                                        <Label>Start period</Label>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            type="date"
                                            value={endPeriod}
                                            onChange={(e) =>
                                                setEndPeriod(e.target.value)
                                            }
                                        />
                                        <Label>End period</Label>
                                    </FormGroup>
                                </div>
                                <div className="button">
                                    <Button
                                        type="INFO"
                                        size="LG"
                                        onClick={loadRatingHandler}
                                        disabled={!isButtonEnabled}
                                    >
                                        Load rating
                                    </Button>
                                </div>
                            </div>
                            {rating && loadedState ? (
                                <Rating
                                    value={rating}
                                    traderId={loadedState.traderId}
                                    startPeriod={loadedState.startPeriod}
                                    endPeriod={loadedState.endPeriod}
                                />
                            ) : (
                                <EmptyState />
                            )}
                        </>
                    )}
                </Container>
            </ContentContainer>
        </div>
    );
};
