import axios from 'axios';

import constants from '../constants';

const apiUrl = constants.apiUrl;

type UserInfo = {
    userId: string;
    paymentStatus: 'OK' | 'PENDING';
};

type GetTokenFunction = () => Promise<string>;

export const getUserInfo = async (
    getTokenFunction: GetTokenFunction
): Promise<UserInfo> => {
    const token = await getTokenFunction();
    return (
        await axios.get(`${apiUrl}/users/me`, {
            headers: { authorization: token },
        })
    ).data;
};

export const getRating = async (
    getTokenFunction: GetTokenFunction,
    startPeriod: string,
    endPeriod: string,
    traderId: string
): Promise<{ requestId: string }> => {
    const token = await getTokenFunction();
    return (
        await axios.get(
            `${apiUrl}/rating?startPeriod=${startPeriod}&endPeriod=${endPeriod}&traderId=${traderId}`,
            {
                headers: { authorization: token },
            }
        )
    ).data;
};

export const checkRatingResult = async (
    getTokenFunction: GetTokenFunction,
    requestId: string
): Promise<{ result?: number; status: 'OK' | 'PENDING' }> => {
    const token = await getTokenFunction();
    return (
        await axios.get(`${apiUrl}/check-rating?requestId=${requestId}`, {
            headers: { authorization: token },
        })
    ).data;
};

export const getCheckoutLink = async (
    getTokenFunction: GetTokenFunction
): Promise<{ url: string }> => {
    const token = await getTokenFunction();
    return (
        await axios.post(
            `${apiUrl}/users/checkout-link`,
            {},
            {
                headers: { authorization: token },
            }
        )
    ).data;
};
