import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 8.5rem;

    h1 {
        margin-bottom: 2rem;
    }

    .login-button {
        padding: 1rem 4.5rem;
    }
`;
