import { CircleSpinner } from '../CircleSpinner';
import { Background, Container } from './styles';

export const LoadingModal = () => {
    return (
        <Background>
            <Container>
                <CircleSpinner size="75px" />
            </Container>
        </Background>
    );
};
