import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

type Props = {
    size: string;
};

export const SpinnerContainer = styled.div<Props>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: ${(props) => props.size};
        height: ${(props) => props.size};
        margin: 8px;
        border: 4px solid #fff;
        border-radius: 50%;
        animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    div:nth-child(1) {
        animation-delay: -0.45s;
    }
    div:nth-child(2) {
        animation-delay: -0.3s;
    }
    div:nth-child(3) {
        animation-delay: -0.15s;
    }
`;
