import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    margin: 2rem;
    justify-content: flex-end;
    align-items: center;

    > div:first-child {
        margin-right: 1.5rem;
        display: flex;
        flex-direction: column;
        text-align: right;
        color: var(--color-neutral-150);
    }

    .logout {
        cursor: pointer;
    }
`;
