import styled from 'styled-components';

export const ContentContainer = styled.div`
    margin: 4rem;
`;

export const Container = styled.div`
    .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .inputs {
            display: flex;
            align-items: center;

            div {
                margin-right: 2rem;
            }

            div:first-child {
                input {
                    width: 400px;
                }
            }
        }

        padding-bottom: 2rem;
        border-bottom: 1px solid var(--color-neutral-150);
    }

    .empty-state {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10rem;
    }

    .rating {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 10rem;

        .rating-value {
            font-size: 10rem;
            font-weight: 600;
            font-family: 'Poppins', sans-serif;
            font-style: normal;
        }

        > p {
            font-size: 1rem;
            color: var(--color-neutral-150);

            b {
                font-weight: 600;
            }
        }
    }

    .subscription-banner {
        display: flex;
        flex-direction: column;
        align-items: center;

        > h2 {
            margin-bottom: 2rem;
        }
    }
`;
