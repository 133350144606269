import { Route, Routes } from 'react-router-dom';
import { HashRouter as Router } from 'react-router-dom';

import { PrivatePage } from './components/PrivateRoute';
import { PageLogin } from './pages/Login';
import { MainPage } from './pages/Main';

function App() {
    return (
        <Router>
            <Routes>
                <Route element={<PrivatePage />}>
                    <Route path="/" element={<MainPage />} />
                </Route>
                <Route path="/login" element={<PageLogin />} />
            </Routes>
        </Router>
    );
}

export default App;
