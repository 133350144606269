import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import constants from './constants';
import reportWebVitals from './reportWebVitals';
import GlobalStyles from './styles/global';

ReactDOM.render(
    <Auth0Provider
        domain={constants.authDomain}
        clientId={constants.authClientId}
        redirectUri={window.location.origin}
        audience="rego-api"
    >
        <React.StrictMode>
            <GlobalStyles />
            <App />
        </React.StrictMode>
    </Auth0Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
