import { useAuth0 } from '@auth0/auth0-react';

import { Button } from '../../components/Button';
import { Container } from './styles';

export const PageLogin = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <Container>
            <h1>Welcome back to Giraffe Platform</h1>
            <Button
                type="OUTLINE"
                size="LG"
                onClick={loginWithRedirect}
                className="login-button"
            >
                LOGIN
            </Button>
        </Container>
    );
};
