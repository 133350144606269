import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Outlet } from 'react-router-dom';

import { LoadingModal } from '../LoadingModal';

export const PrivatePage = () => {
    const { isLoading, isAuthenticated } = useAuth0();
    return isLoading ? (
        <LoadingModal />
    ) : !isAuthenticated ? (
        <Navigate to="/login" />
    ) : (
        <Outlet />
    );
};
