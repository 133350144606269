import { useAuth0 } from '@auth0/auth0-react';

import { Avatar } from './components/Avatar';
import { Container } from './styles';

export const Header = () => {
    const { user, logout } = useAuth0();
    return (
        <Container>
            <div>
                <div className="email">
                    <p>{user?.email}</p>
                </div>
                <div
                    className="logout"
                    onClick={() => logout({ returnTo: window.location.origin })}
                >
                    <p>logout</p>
                </div>
            </div>
            <Avatar imgUrl={user?.picture as string} />
        </Container>
    );
};
