import styled from 'styled-components';

export const Background = styled.div`
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0px;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
`;

export const Container = styled.div`
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #262a34;
    border-radius: 1rem;
    padding: 1rem;
`;
